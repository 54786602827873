import React, { useState, useEffect } from "react";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import UrlImageDownloader from "react-url-image-downloader";
import AWS from "aws-sdk";
import downloadImagesAsZip from "files-download-zip";

var zip = JSZip();

const selectedImgStyle = {
  transform: "translateZ(0px) scale3d(0.9, 0.9, 1)",
  transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s",
};
const cont = {
  backgroundColor: "#eee",
  cursor: "pointer",
  overflow: "hidden",
  position: "relative",
};

const SelectedImage = ({
  index,
  photo,
  margin,
  direction,
  top,
  left,
  selected,
  id,
}) => {
  const [isSelected, setIsSelected] = useState(selected);
  //calculate x,y scale
  const sx = (100 - (30 / photo.width) * 100) / 100;
  const sy = (100 - (30 / photo.height) * 100) / 100;
  selectedImgStyle.transform = `translateZ(0px) scale3d(${sx}, ${sy}, 1)`;

  if (direction === "column") {
    cont.position = "absolute";
    cont.left = left;
    cont.top = top;
  }

  const handleSelect = (e) => {
    console.log("v", photo);
    saveAs(photo?.src, "example.jpg");
    // var element = document.createElement("a");
    // var file = new Blob([photo.src], {
    //   type: photo?.type == "video" ? "video/*" : "image/*",
    // });
    // console.log("file", file);
    // element.href = URL.createObjectURL(file);
    // element.download = "file.jpg";
    // element.click();
  };

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  return (
    <div
      style={{ ...cont, height: "300px" }}
      className={!isSelected ? "not-selected " : "col-md-3 col-sm-6 col-xs-12 "}
    >
      <div className="bg-white">
        {photo?.type == "image" ? (
          <img
            alt={photo.title}
            {...photo}
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
            loading="eager"
          />
        ) : (
          <video width={photo.width} height={photo.height} controls>
            <source src={photo?.src} type="video/mp4" />
            {/* <source src={photo?.src} type="video/ogg" /> */}
            Your browser does not support the video tag.
          </video>
        )}
      </div>

      {/* <style>{`.not-selected:hover{outline:2px solid #06befa}`}</style> */}
      {/* <a
        href={photo?.src}
        download
        // onClick={handleSelect}
        // onClick={() => download(photo?.src)}
        className="position-absolute download-btn download-back"
      >
        <FiDownload className="fidownload" />
        <UrlImageDownloader imageUrl={photo.src} />
      </a> */}
      <div
        className="position-absolute download-btn download-back"
        onClick={handleSelect}
      >
        <FiDownload className="fidownload" />
      </div>
    </div>
  );
};

export default SelectedImage;
