import React, { useState, useCallback, useEffect } from "react";
import Gallery from "react-photo-gallery";
import SelectedImage from "./SelectedImage";
import { photos } from "./photos";
import "bootstrap/dist/css/bootstrap.min.css";
import { FiDownload } from "react-icons/fi";
import "./App.css";
import logo from "./assets/round.png";
import axios from "axios";
import downloadImagesAsZip from "files-download-zip";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import JSZip from "jszip";
import FileSaver from "file-saver";
import FullPageLoader from "./FullPageLoader";
import ReactPlayer from 'react-player';
let a = [];
let page = 1
function App() {
  const [apiData, setapiData] = useState([]);
  const [selectImage, setSelectImage] = useState([]);
  const [allImage, setAllImage] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;
  const [loading, setLoading] = useState(true);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  // console.log("a", a);
  if (selectImage.length === 0) {
    a.push();
  }

  const apiCall = async () => {
    // axios.get(`http://localhost/user/booking/token/${window.location.pathname?.split("")}`)

    console.log(window.location.search?.split("=")[1]);
    // setFullScreenLoading(true);

    await axios
      .get(
        `https://api.elpixie.com/user/booking/token/${
          window.location.search?.split("=")[1]
        }`
      )
      .then((res) => {
        console.log("res", res);
        let dummy = res?.data?.data?.image?.map((v, i) => {
          return {
            id: i + 1,
            src: v,
            // src2: v.substring(0, v.lastIndexOf("3") + 1),
            width: 4,
            height: 3,
            type: v?.split("/")[5]?.split(".")[1] == "mp4"|| v?.split("/")[5]?.split(".")[1] == "mov" ? "video" : "image",
          };
        });
        console.log("v", dummy);
        setAllImage(dummy);
        setTimeout(() => {
          setFullScreenLoading(false);
        }, 2000);
      });
  };

  useEffect(() => {
    apiCall();
    // addpagination();
  }, []);
  const callstring = async(v)=>{
    let inputString = v;

    // Split the string using "/"
    // Split the string using "/"
let parts = inputString.split("/");
console.log(parts,"1")
// Modify the necessary parts
let filename = parts.pop(); // Remove the last part (filename)
console.log(filename,"2")

let data = filename.split(".")[1]



let folder = parts.pop(); // Get the second-last part (folder name)
console.log(folder,"3")

let namechangefolder = parts.pop();
console.log(namechangefolder,"4",parts)


let folder1 = namechangefolder.replace("profilevideo_thumbnail", "profile"); // Replace "profilevideo_thumbnail" with "profile"
console.log(folder,"5")

// Construct the new string
return parts.join("/") + "/" + folder1 + "/" + filename.replace(data, folder);
    
    
  }

  const addpagination = async () => {
    // setLoading(true);
    await axios
      .post(
        `https://api.elpixie.com/user/booking/token/${
          window.location.search?.split("=")[1]
        }`,
        { page: currentPage, limit: perPage }
      )
      .then(async(res) => {
        console.log("res", res,);
        let dummy = []
        let count = apiData.length
        for (let index = 0; index < res?.data?.data?.compress_image?.length; index++) {
          const element = res?.data?.data?.compress_image[index];
          let object = {
            id: count + 1,
            src: element?.split("/")[4] == "profilevideo_thumbnail"?await callstring(element):element,
            // src2: v.substring(0, v.lastIndexOf("3") + 1),
            width: 4,
            height: 3,
            type: element?.split("/")[4] == "profilevideo_thumbnail" ? "video" : "image",
          }
          dummy.push(object)
          count++
        }
      // await res?.data?.data?.compress_image?.map(async(v, i) => {
      //     return {
      //       id: i + 1,
      //       src: v?.split("/")[4] == "profilevideo_thumbnail"?await callstring(v):v,
      //       // src2: v.substring(0, v.lastIndexOf("3") + 1),
      //       width: 4,
      //       height: 3,
      //       type: v?.split("/")[5]?.split(".")[1] == "mp4"|| v?.split("/")[5]?.split(".")[1] == "mov" ? "video" : "image",
      //     };
      //   });
        console.log("v", dummy);
        if(dummy.length == 0) {
          setLoading(false)
        }else{
          setLoading(true)

        }
        if (currentPage == 1) {
          setapiData(dummy);
        } else {
          setapiData([...apiData, ...dummy]);
        }
        // setLoading(false);
      });
  };

  useEffect(() => {
    addpagination();
  }, [currentPage]);

  const handleScroll = () => {
    var lastLiOffset;
    var pageOffset;


    var lastLi = document.querySelector("div");

    lastLiOffset = lastLi.offsetTop + lastLi.offsetHeight;
    pageOffset = window.pageYOffset + window.innerHeight;

    if (pageOffset >= lastLiOffset - 1) {

      setCurrentPage((prevPage) => prevPage + 1);
    }

      // const windowHeight = window.innerHeight;
      // const scrollTop = document.documentElement.scrollTop;
      // const offsetHeight = document.documentElement.offsetHeight;
      // console.log(windowHeight,scrollTop ,offsetHeight)
      // if (windowHeight + scrollTop >= offsetHeight - 1 && !loading) {
      //   setCurrentPage((prevPage) => prevPage + 1);
      // }
    
    
  };
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  const imageRenderer = useCallback(({ index, left, top, key, photo }) => {
    return (
      <SelectedImage
        key={key}
        margin={"2px"}
        index={index}
        photo={photo}
        left={left}
        top={top}
      />
    );
  });
  const handleDownloadSingleImage = (photo,id) => {
    console.log("v", photo,allImage);
    let fetchserch = allImage.find(obj => obj["id"] === photo?.id);
    console.log("fetch", fetchserch)
    const link = document.createElement("a");
    link.href = fetchserch?.src;
    link.download = fetchserch?.type=="image"?`image${id + 1}.jpg`:`video${id + 1}.${fetchserch?.type}`;
    link.click();

    // saveAs(photo?.src, "example.jpg");

    // var element = document.createElement("a");
    // var file = new Blob([photo.src], {
    //   type: photo?.type == "video" ? "video/*" : "image/*",
    // });
    // console.log("file", file);
    // element.href = URL.createObjectURL(file);
    // element.download = "file.jpg";
    // element.click();
  };
  console.log("issl", apiData);

  const handleDownloadAllImage = async () => {
    console.log("images all", apiData);
    // const imgDataArray = apiData?.map((v) => v?.src);
    // console.log("imgDataArray", imgDataArray);
    setFullScreenLoading(true);

    const zip = new JSZip();
    const promise = allImage.map(async (AllImageUrl, index) => {
      const response = await fetch(AllImageUrl.src);
      const blob = await response.blob();
      const fileName = `image${index + 1}.${AllImageUrl.src?.split("/")[5]?.split(".")[1]}`;
      zip.file(fileName, blob);
    });
    await Promise.all(promise);
    const zipBlob = await zip.generateAsync({ type: "blob" });
    FileSaver.saveAs(zipBlob, "images.zip");
    setTimeout(() => {
      setFullScreenLoading(false);
    }, 2000);

    // apiData.map(async (val, index) => {
    //   console.log("val", val);
    //   let link = document.createElement("a");
    //   link.href = await fetch(val?.src);
    //   link.download = `image${index + 1}.jpg`;
    //   link.click();

    // let response = await fetch(val?.src);
    // let blob = await response.blob();
    // saveAs(blob, "example.jpg");
    // });
    //   imageUrls.forEach(url => {
    //     fetch(url)
    //       .then(response => response.blob())
    //       .then(blob => {
    //         const url = window.URL.createObjectURL(new Blob([blob]));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', '');
    //         document.body.appendChild(link);
    //         link.click();
    //         link.parentNode.removeChild(link);
    //       });
    //   });
    // };

    // for (let i = 0; i < imgDataArray.length; i++) {
    //   setTimeout(() => {
    //     saveAs(
    //       imgDataArray[i],
    //       `example[i].${
    //         imgDataArray[i]
    //           ?.split("/")
    //           [imgDataArray[i]?.split("/")?.length - 1]?.split(".")[1]
    //       }`
    //     );
    //   }, 2000);

    //   // var a = document.createElement("a");
    //   // console.log("i", i);
    //   // //  console.log( image )
    //   // a.href = apiData[i]?.src;
    //   // a.download = apiData[i]?.src;
    //   // document.body.appendChild(a);
    //   // a.click();
    //   // document.body.removeChild(a);
    //   // console.log("ggg", i);
    //   // var element = document.createElement("a");
    //   // var file = new Blob([apiData[i]?.src], {
    //   //   type: apiData[i]?.type == "video" ? "video/*" : "image/*",
    //   // });
    //   // console.log("file", file);
    //   // element.href = URL.createObjectURL(file);
    //   // element.download = apiData[i]?.src;
    //   // element.click();
    //   // apiData.forEach(function (e) {
    //   //   fetch(e.src)
    //   //     .then((res) => res.blob()) // Gets the response and returns it as a blob
    //   //     .then((blob) => {
    //   //       saveAs(blob, e.src);
    //   //     });
    //   // });
    // }

    // imgDataArray?.map((v) => saveAs(v, "example.jpg"));

    // saveAs(photo?.src, "example.jpg");

    // const zipFileName = "imagesZip";

    // downloadImagesAsZip.execute(imgDataArray, zipFileName, function () {
    //   // callback function

    //   alert("Done");
    // });
    // saveAs(photo?.src, "example.jpg");
    // var element = document.createElement("a");
    // var file = new Blob([photo.src], {
    //   type: photo?.type == "video" ? "video/*" : "image/*",
    // });
    // console.log("file", file);
    // element.href = URL.createObjectURL(file);
    // element.download = "file.jpg";
    // element.click();
  };

  const handleDownloadSelect = async () => {
    // const link = document.createElement("a");
    // link.href = photo?.src;
    // link.download = `image${id + 1}.jpg`;
    // link.click();
    setFullScreenLoading(true);
    const zip = new JSZip();
    const promises = selectImage.map(async (imageUrl, index) => {
      console.log(imageUrl)
      let fetchserch = allImage.find(obj => obj["id"] === imageUrl?.id);
      const response = await fetch(fetchserch.src);
      
      const blob = await response.blob();
      const fileName = fetchserch?.type=="image"?`image${index + 1}.${fetchserch.src?.split("/")[5]?.split(".")[1]}`:`video${index + 1}.${fetchserch.src?.split("/")[5]?.split(".")[1]}`;
      
      zip.file(fileName, blob);
   

    });
    
    await Promise.all(promises);
    const zipBlob = await zip.generateAsync({ type: "blob" });
    FileSaver.saveAs(zipBlob, "images.zip");
    setTimeout(() => {
      setFullScreenLoading(false);
    }, 500);
    setSelectImage([])

    //   //   console.log("val", val, index);
    //   //   // let response = await fetch(val?.src);
    //   //   // let blob = await response.blob();
    //   //   // saveAs(blob, "example.jpg");
    //   //   // saveAs(val?.src, "example.jpg");
    // a = [];
    // window.location.reload();
  };

  const cont = {
    // backgroundColor: "#eee",
    // cursor: "pointer",
    overflow: "hidden",
    position: "relative",
  };
  const handleSelectedPhoto = (photo, i, e) => {
    // console.log("photo", e.target.checked);
    // console.log("photo.id", photo.id);
    // if (e.target.checked) {
    //   a.push(photo);
    //   console.log("a", a);
    //   setSelectImage(a);
    // } else {
    //   let value = selectImage.filter((v) => v?.id !== photo?.id);
    //   console.log("value", value);
    //   setSelectImage(value);
    //   a = a.filter((v) => v?.id !== photo?.id);
    // }
    
    let data = allImage[i]
    setSelectImage([...selectImage, data])
    // if (selectImage.includes(photo)) {
    //   setSelectImage(selectImage.filter((url) => url !== photo));
    // } else {
    //   setSelectImage([...selectImage, photo]);
    // }
    // setSelectImage(a);
  };

  return (
    <>
      {fullScreenLoading && <FullPageLoader />}
      <div className="mt-2">
        {/* <div className="d-flex logo-btn mb-3">
        <img src={logo} className="logo" />
        <p className="text-end">
          <div className="btn btn-primary mt-4" onClick={handleSelect}>
            Download All
          </div>
        </p>
      </div> */}
        <div class="header mb-5">
          <img src={logo} className="logo" />
          {/* <div class="header-right mt-2" role="button">
          <a class="active" onClick={handleSelect}>
            Download All
          </a>
        </div> */}
        </div>
        <div className="container main-div">
          <div className="d-flex header justify-content-between align-items-center">
            <h5 className="fw-bold">Photos & Videos</h5>
            <div class="">
              <div class="" role="button">
                {selectImage.length === 0 ? (
                  <a
                    class="active"
                    id="load-button"
                    onClick={() => handleDownloadAllImage()}
                  >
                    DOWNLOAD ALL
                  </a>
                ) : (
                  <a class="active" onClick={() => handleDownloadSelect()}>
                    DOWNLOAD SELECTED
                  </a>
                )}
              </div>
            </div>
          </div>
          {/* {totalPages && currentPage < totalPages && ()} */}
          <div
            className="row m-0 g-3 pb-4"
            // style={{ position: "relative", zIndex: "0" }}
          >
            {/* <Gallery photos={photos} renderImage={imageRenderer} /> */}
            {apiData.map((photo, i) => {
              return (
                <div
                  style={{ ...cont, height: "300px" }}
                  className={"col-md-6 col-sm-6 col-xs-12 "}
                >
                  {photo?.type === "image" ? (
                    <>
                      <img
                        alt={`Image ${i}`}
                        src={photo?.src}
                        loading="lazy"
                        // effect="blur"
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "100%",
                          borderRadius: "10px",
                        }}
                      />
                      {/* <Img />*/}
                    </>
                  ) : (
                    // </Img>
                    <div className="h-100">
                    <ReactPlayer
                    style={{height:"100%"}}
      url={photo?.src}
      width="168"
      height="100%"
      controls
    />
    </div>
    // <div className="h-100">
    //                 <video
    //                   style={{
    //                     width: "100%",
    //                     height: "100%",
    //                     borderRadius: "10px",
    //                   }}
    //                   controls
    //                 >
    //                   <source src={"https://elpixieproduction.s3.amazonaws.com/6322161590a68d73a19adae9/profile/1683729129868.mov"} type="video/mp4" />
    //                   {/* <source src={photo?.src} type="video/ogg" /> */}
    //                   Your browser does not support the video tag.
    //                 </video>
    //                 </div>
                  )}

                  {/* <style>{`.not-selected:hover{outline:2px solid #06befa}`}</style> */}
                  {/* <a
        href={photo?.src}
        download
        // onClick={handleSelect}
        // onClick={() => download(photo?.src)}
        className="position-absolute download-btn download-back"
      >
        <FiDownload className="fidownload" />
        <UrlImageDownloader imageUrl={photo.src} />
      </a> */}
                  <div
                    className="position-absolute download-btn download-back"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleDownloadSingleImage(photo);
                    }}
                  >
                    <FiDownload className="fidownload" />
                  </div>
                  <div
                    className="position-absolute download-check"
                    style={{ cursor: "pointer" }}
                  >
                    <label class="label-checkbox ">
                      <input
                        type="checkbox"
                        onClick={(e) => {
                          handleSelectedPhoto(photo, i, e);
                        }}
                      />
                      <span class="checkmark border border-dark"></span>
                    </label>
                  </div>
                </div>
              );
            })}
          
          {loading?<div
                class="d-flex justify-content-center"
                // style={{ zIndex: "0" }}
              >
          <div className="d-flex header justify-content-between align-items-center">
            {/* <h5 className="fw-bold">Photos & Videos</h5> */}
            <div class="">
              <div class="" role="button">
                
                  <a
                    class="active"
                    id="load-button"
                    onClick={() => (setCurrentPage((prevPage) => prevPage + 1))}
                  >
                    Load More.....
                  </a>
                
              </div>
            </div>
          </div>
              
                
              </div>:null}
           
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
